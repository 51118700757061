import React from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Bottom from "../../components/HomePage/Bottom/Bottom";
import Footer from "../../components/GlobalComponents/Footer/Footer";

const ContactUsPage = () => {
  return (
    <div style={{ backgroundColor: '#7A1212' }}>
      <div className="ovf-hidden">
        <Header />
        <Bottom />
        <div style={{ backgroundColor: '#fff' }}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
